export default {
  h1: {
    fontFamily: "'Saira Semi Condensed', sans-serif",
  },
  h2: {
    fontFamily: "'Saira Semi Condensed', sans-serif",
    fontWeight: 500,
    fontSize: 30,
  },
  h3: {
    fontFamily: "'Bakbak One', cursive",
    fontWeight: 500,
    fontSize: 25,
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: "'Saira Semi Condensed', sans-serif",
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
  },
  h6: {
    color: "#fff",
    fontSize: "18px !important",
    textAlign: "left",
    fontFamily: "Orbitron ",
    fontWeight: "500 !important",
    lineHeight: "35px",
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
  body1: {
    color: "#fff",
    fontSize: "15px",
    fontFamily: "'Saira', sans-serif",
    fontWeight: "300",
    lineHeight: "24px",
  },
  body2: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28px",
    color: "#fff",
    fontFamily: "'Saira', sans-serif",
  },

  body3: {
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "23px",
    color: "#fff",
    fontFamily: "'Saira', sans-serif",
  },
};
