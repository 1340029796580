// const url = "https://node-move2earn.mobiloitte.com";
// const url = "http://172.16.1.243:1930";

const url = "https://nodepune-move2earn.mobiloitte.io";

const nft = `${url}/api/v1/nft`;
const user = `${url}/api/v1/user`;
const stake = `${url}/api/v1/stake`;
const contactUs = `${url}/api/v1/contactUs`;
const updateSubscribe = `${url}/api/v1/updateSubscribe`;
let statics = `${url}/api/v1/static`;
let faqs = `${url}/api/v1/faq`;
let medias = `${url}/api/v1/media`;
let partners = `${url}/api/v1/partner`;
let teams = `${url}/api/v1/team`;

const ApiConfig = {
  connectWallet: `${user}/connectWallet`,
  subscribeNow: `${user}/subscribeNow`,
  latestcontactUs: `${contactUs}/contactUs`,

  createNFT: `${nft}/createNFT`,
  listNFT: `${nft}/listNFT`,
  stakeNFT: `${stake}/stakeNFT`,
  stakeList: `${stake}/stakeList`,
  unstakeNFT: `${stake}/unstakeNFT`,
  userContactUs: `${contactUs}/userContactUs`,
  userUpdateSubscribe: `${updateSubscribe}/userUpdateSubscribe`,

  //static
  static: `${statics}/static`,

  //faq
  faq: `${faqs}/faq`,

  //media
  media: `${medias}/media`,

  //partners
  partner: `${partners}/partner`,

  //team
  team: `${teams}/team`,
};
export default ApiConfig;
