import React from 'react';
import { makeStyles, Box, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: "#000",
        display: 'flex',
        // flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        // left: 0,
        // padding: theme.spacing(3),
        position: 'fixed',
        // top: 0,
        width: '100%',
        zIndex: 2000,
    },
    loader1: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    loader: {
        width: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export default function PageLoading() {
    const classes = useStyles();
    return (
        <div className={classes.root}>

            <Box className={classes.loader1}>
                <Box className={classes.loader}>
                    <img src="/images/logo.png" alt="loader" style={{ width: "100%" }} />
                </Box>
            </Box>
        </div>
    )
}
