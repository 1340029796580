import React, { createContext, useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
// import { toast } from "react-toastify";
import { SUPPORTED_WALLETS } from "src/connectors";
import { toast } from "react-toastify";
import { ACTIVE_NETWORK, NftContractAddress } from "src/constants";
import GenerativeNFTABI from "src/ABI/GenerativeNFTABI.json";
import {
  getContract,
  getWeb3ContractObject,
  getWeb3Obj,
  swichNetworkHandler,
} from "src/utils";
import axios from "axios";
import ApiConfig from "src/config/APICongig";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};
const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};
export default function AuthProvider(props) {
  const { activate, account, chainId, library } = useWeb3React();
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [MAX_NFT_SUPPLY, setMAX_NFT_SUPPLY] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [adminWalletAddress, setAdminWalletAddress] = useState("");
  const [nftPrice, setNftPrice] = useState(0);
  const [preSaleActive, setPreSaleActive] = useState(false);
  const [publicSaleActive, setPublicSaleActive] = useState(false);
  const [userNFTList, setUserNFTList] = useState([]);
  const [isLogin, setIsLogin] = useState(false);
  // const [tokenSession, setTokenSession] = useState([]);
  const [userData, setUserData] = useState([]);

  let data = {
    balanceOfValue,
    MAX_NFT_SUPPLY,
    totalSupply,
    nftPrice,
    preSaleActive,
    publicSaleActive,
    adminWalletAddress,
    userNFTList,
    userData,
    isLogin,
    updateUser: (account) => {
      setSession(account);
    },
    getCurrentMintingDetails: () => getCurrentMintingDetails(),
    connectWallet: (data) => connectWalletHandler(data),
  };

  const userNFTListHadler = async (balanceOf, cancelTokenSource) => {
    setUserNFTList([]);
    const contract = getContract(
      NftContractAddress,
      GenerativeNFTABI,
      library,
      account
    );

    try {
      for (let i = 0; i < balanceOf; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());
        const res = await axios.get(filter, {
          cancelToken: cancelTokenSource && cancelTokenSource.token,
        });
        if (res.status === 200) {
          setUserNFTList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (chainId != ACTIVE_NETWORK) {
      swichNetworkHandler();
    }
  }, [chainId]);

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    if (balanceOfValue > 0 && ACTIVE_NETWORK == chainId) {
      userNFTListHadler(balanceOfValue, cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [balanceOfValue, account, chainId]); //eslint-disable-line

  const getBalanceOf = async (abi, address, account) => {
    try {
      const contract = await getWeb3ContractObject(abi, address);
      const balanceOf = await contract.methods.balanceOf(account).call();

      return balanceOf.toString();
    } catch (error) {
      console.log("ERROR--", error);
      return 0;
    }
  };

  async function getBalanceOfFun() {
    setBalanceOfValue(
      await getBalanceOf(GenerativeNFTABI, NftContractAddress, account)
    );
  }

  const getCurrentMintingDetails = async () => {
    try {
      const web3 = await getWeb3Obj();

      const contractObj = await getWeb3ContractObject(
        GenerativeNFTABI,
        NftContractAddress
      );
      if (account) {
        getBalanceOfFun();
      }

      const preSaleActive = await contractObj.methods.preSaleActive().call();
      setPreSaleActive(preSaleActive);
      const publicSaleActive = await contractObj.methods
        .publicSaleActive()
        .call();

      setPublicSaleActive(publicSaleActive);
      const NFT_PRICE = await contractObj.methods.getNFTPrice(1).call();
      const getNFTPrice = web3.utils.fromWei(NFT_PRICE.toString());
      setNftPrice(getNFTPrice);
      console.log("nftPrice12", nftPrice);
      const MAX_NFT_SUPPLY = await contractObj.methods.MAX_NFT_SUPPLY().call();
      setMAX_NFT_SUPPLY(Number(MAX_NFT_SUPPLY.toString()));

      const totalSupply = await contractObj.methods.totalSupply().call();

      setTotalSupply(Number(totalSupply.toString()));
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getContractDetails = async () => {
    try {
      const contractObj = await getWeb3ContractObject(
        GenerativeNFTABI,
        NftContractAddress
      );
      const adminAccount = await contractObj.methods.owner().call();
      setAdminWalletAddress(adminAccount);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    getCurrentMintingDetails();
    getContractDetails();
  }, [account]); //eslint-disable-line

  const connectWalletHandler = (data) => {
    try {
      const connector = data.connector;

      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          toast.error(JSON.stringify(error.message));
          localStorage.removeItem("walletName");
          activate(connector);
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === localStorage.getItem("walletName")
      );
      if (selectectWalletDetails[0]?.data) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    }
  }, []);

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line
  useEffect(() => {
    if (account) {
      connectWalletAPICall();
      // setWalletAddress(account);
    } else {
      setIsLogin(false);
      setTokenSession(null);
    }
  }, [account]);
  // console.log("account", account);

  const connectWalletAPICall = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.connectWallet,
        data: {
          walletAddress: account,
          location_place: "New Delhi",
          browser: "Crome",
          ip_Address: "172.16.1.176",
        },
      });
      if (res.data.statusCode === 200) {
        setTokenSession(res.data.result.token);
        setUserData(res.data.result);
        setIsLogin(true);
      } else {
        toast.error(res.data.responseMessage);
        setTokenSession();
      }
    } catch (error) {
      setTokenSession();
      console.log("ERROR", error);
      toast.error(error.message);
    }
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
