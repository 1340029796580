import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#000",
    position: "relative",
    backgroundColor:
      "radial-gradient(30.95% 30.95% at 30.74% 10.25%, rgba(30, 184, 8, 0.2) 0%, rgba(30, 184, 8, 0) 100%) , radial-gradient(86.99% 51.58% at 86.99% 48.42%, rgba(30, 184, 8, 0.3) 0%, rgba(0, 0, 0, 0) 100%) , #000000",
  },
  MainLayout: {
    minHeight: "calc(100vh - 415px)",
    backgroundRepeat: "no-reapeat",
    backgroundSize: "cover",
    // backgroundImage: "url(images/background.svg)",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(history.location);
  return (
    <div className={classes.root}>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;
