import React, { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  FormHelperText,
  TextField,
  IconButton,
  Divider,
} from "@material-ui/core";
import {} from "react-feather";
import { Instagram, Twitter } from "@material-ui/icons";
import { GrFacebookOption } from "react-icons/gr";
import Scroll from "react-scroll";
import { FaDiscord } from "react-icons/fa";
import { useHistroy } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ApiConfig from "src/config/APICongig";
import axios from "axios";
import { toast } from "react-toastify";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { Form, Formik } from "formik";
import * as yep from "yup";
const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  textFeild: {
    backgroundColor: "#2F2F2F",
    fontFamily: "'Ubuntu', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "11px",
    "& hover": {
      borderRadius: "50px",
    },

    // paddingRight:'0px',
    // padding:"5px 15px 10px 15px",
    borderRadius: "50px",
    // 1borderRadius:"25px",
    "& input": {
      color: "white",
      // backgroundColor:"#2F2F2F"
      padding: "10px 0px 10px 15px",
    },
  },
  footerSection: {
    padding: "15px 0px 15px",
    // background: "#000",
    background:
      "linear-gradient(rgba(1, 251, 180, 0.04),rgba(1, 251, 180, 0.04)),url('./images/fback.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    "& h2": {
      fontSize: "30px",
      color: "#fff",
      fontWeight: "700",
      fontFamily: "'Orbitron', sans-serif",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: "25px",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "20px",
      },
    },
    "& p": {
      [theme.breakpoints.down("md")]: {
        fontSize: "11px !important",
      },
    },
  },
  textBtn: {
    color: "#000",
    width: "200px",
    fontSize: "17px",
    fontFamily: "'Orbitron', sans-serif",

    // border: "1px solid #73FF71",
    border: "1px solid #1EB808",
    // margin: "0px -13px 0px 0px",
    background:
      "linear-gradient(180deg, #06FBB6 0%, rgba(255, 255, 255, 0.98) 100%)",
    boxShadow: "0px 0px 30px #1EB808",
    borderRadius: "55px",
    fontWeight: "800",
    "&:hover": {
      background: "#1EB808",
    },
  },
  menuButton: {
    fontSize: "12px",
    // lineHeight: "35px",
    cursor: "pointer",
    fontWeight: "300",
    borderRadius: 0,
    minWidth: "auto",
    textTransform: "uppercase",
    color: "#fff",
    fontFamily: "'Orbitron', sans-serif",
    // fontFamily: "'Saira Semi Condensed', sans-serif",
    letterSpacing: "2px",
    margin: "5px 3px",
    [theme.breakpoints.only("xs")]: {
      letterSpacing: "0px",
    },
    "@media (max-width: 1280px)": {
      fontSize: "11px",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
    },
    "&.active": {
      color: "#000",
    },
    "&:hover": {
      color: "#1EB808",
    },
    "& .marginsec": {},
  },
  widthBox: {
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "flex-end",
    padding: "20px 0px 5px",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "start",
      padding: "20px 0px 10px",
    },
  },
  socialIcons: {
    color: "white",
    "&:hover": {
      color: "#1EB808",
    },
  },
  centerbox: {
    display: "block",
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContents: "center",
      alignItems: "center",
    },
    "& .buttonsub": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      paddingTop: "8px",
    },
  },
  bottomText: {
    display: "flex",
    justifyContent: "flex-end",

    [theme.breakpoints.only("xs")]: {
      justifyContent: "start",
    },
  },

  botomBox2: {
    position: "absolute",
    right: "11%",

    [theme.breakpoints.down("sm")]: {
      right: "-3%",
      position: "relative",
    },
  },
  botomBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  erroBox: {
    margin: "0px",
    fontSize: "12px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
  questionhead: {
    padding: "50px 0px",
  },
  textpadding: {
    paddingLeft: "123px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
}));

export default function Liquidity() {
  const history = useHistory();
  const classes = useStyles();
  const [email, setemail] = useState();
  const [Loader, setLoader] = useState();
  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid email address"
      )
      .required("Email address is required")
      .max(256, "Should not exceeds 256 characters."),
  });

  const formInitialValue = {
    email: "",
  };
  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("file", values.email);
      // formData("email" : email)
      setLoader(true);
      const res = await axios({
        method: "POST",
        url: ApiConfig.subscribeNow,
        data: {
          email: values.email,
        },
      });
      console.log("window.sessionStorage.getItem", res);
      if (res.data.statusCode === 200) {
        setLoader(false);
        toast.success(res.data.responseMessage);
      } else {
        setLoader(false);
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      setLoader(false);
      console.log("ERROR", error);
      // toast.error(error.data.message);
      if (error.response) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error(error.data.message);
      }
    }
  };
  return (
    <>
      <Box className={classes.questionhead}>
        <Container>
          <Box
            // pb={6}

            className="stillQues"
            align="center"
          >
            <Typography variant="h2">Still have some questions?</Typography>
            <Typography variant="body1">
              If you cannot find answer to your question in our FAQ, you can
              always contact us. We will answer to you shortly
            </Typography>

            <Box align="center" mt={5} className={classes.btnsec}>
              <Button
                // className={classes.Button}
                className={classes.textBtn}
                onClick={() => {
                  history.push({
                    pathname: "/contact",
                  });
                }}
              >
                Contact Us
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={classes.footerSection}>
        <Container>
          {/* <Divider style={{ border: "1px solid #4B4B4B" }} /> */}

          <Grid container spacing={2} style={{ paddingTop: "20px" }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={7}
              className={classes.centerbox}
            >
              <Box style={{ maxWidth: "200px" }}>
                <img src="./images/logo.png" alt="Logo" width="100%" />
              </Box>
              <Box className={classes.icons}>
                <Box display="flex">
                  <Box>
                    <IconButton
                      className="iconBtnBorder"
                      target="_blank"
                      href="https://discord.com/"
                    >
                      <FaDiscord className={classes.socialIcons} />
                    </IconButton>

                    <IconButton
                      target="_blank"
                      className="iconBtnBorder"
                      href="https://www.facebook.com/"
                    >
                      <GrFacebookOption className={classes.socialIcons} />
                    </IconButton>

                    <IconButton
                      target="_blank"
                      className="iconBtnBorder"
                      href="https://www.twitter.com/"
                    >
                      <Twitter className={classes.socialIcons} />
                    </IconButton>

                    <IconButton
                      target="_blank"
                      className="iconBtnBorder"
                      href="https://www.instagram.com/"
                    >
                      <Instagram className={classes.socialIcons} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={5}
              className={classes.centerbox}
            >
              <Box className="buttonsub">
                <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  to="about"
                  onClick={() => {
                    history.push({
                      pathname: "/about",
                    });
                  }}
                >
                  {" "}
                  ABOUT{" "}
                </ScrollLink>
                {/* <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push({
                      pathname: "/terms",
                    });
                  }}
                >
                  {" "}
                  Terms & Conditions{" "}
                </ScrollLink> */}
                <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push({
                      pathname: "/Mint",
                    });
                  }}
                >
                  {" "}
                  MINT
                </ScrollLink>
                {/* <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  to="policy"
                  onClick={() => {
                    history.push({
                      pathname: "/policy",
                    });
                  }}
                >
                  {" "}
                  Privacy Policy{" "}
                </ScrollLink> */}

                <a
                  className={classes.menuButton}
                  target="_blank"
                  // href="https://docs.google.com/document/d/1dIfI2Sy_euzhjGbjs0KtTRHcr_JJ-FVD9zDySMJM9II/edit"
                  href="https://moonstep-io.gitbook.io/move-to-earn/"
                >
                  WHITEPAPER{" "}
                </a>
                {/* <a
                  className={classes.menuButton}
                  target="_blank"
                  href="images/PDF.pdf"
                  download="PDF.pdf"
                >
                  LITEPAPER{" "}
                </a> */}
                {/* <a
                  className={classes.menuButton}
                  onClick={() => {
                    history.push({
                      pathname: "/roadmap",
                    });
                  }}
                >
                  ROADMAP{" "}
                </a> */}

                <ScrollLink
                  className={`${classes.menuButton} marginsec`}
                  smooth={true}
                  duration={500}
                  to="roadmap"
                  tabIndex="2"
                  onClick={() => history.push("/?id=roadmap")}
                  style={{ marginLeft: "0px" }}
                >
                  {" "}
                  ROADMAP
                </ScrollLink>
              </Box>
              <Box className={classes.textpadding}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "11px",
                    fontFamily: "'Orbitron', sans-serif",
                    margin: "30px 0 8px",
                    letterSpacing: "1px",
                  }}
                >
                  Subscribe for updates and announcements
                </Typography>
                <Formik
                  initialValues={formInitialValue}
                  initialStatus={{
                    success: false,
                    successMsg: "",
                  }}
                  validationSchema={formValidationSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    // contact,
                    setFieldValue,
                  }) => (
                    <Form>
                      <Box className={classes.widthBox}>
                        {/* <Typography variant="body1">Email Address</Typography> */}
                        <TextField
                          placeholder="Enter your email"
                          // variant="outlined"
                          name="email"
                          autoComplete="off"
                          // fullWidth
                          className={classes.textFeild}
                          error={Boolean(touched.email && errors.email)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <Button
                                className={classes.textBtn}
                                // onClick={handleSubmit}
                                type="submit"
                                disabled={Loader}
                              >
                                Subscribe {Loader && <ButtonCircularProgress />}
                              </Button>
                            ),
                          }}
                        />
                        <FormHelperText error className={classes.erroBox}>
                          {touched.email && errors.email}
                        </FormHelperText>
                      </Box>

                      {/* <Box mt={2} align="right">
                      <Button variant="contained" type="submit" color="primary">
                        Submit{Loader && <ButtonCircularProgress />}
                      </Button>
                    </Box> */}
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <hr style={{ border: " 0.5px solid #039E90" }} />
            <Box mt={2} className={classes.botomBox}>
              <Box>
                <Typography
                  variant="body2"
                  style={{ fontSize: "14px", fontWeight: "300" }}
                >
                  &copy;2022 -moonstep | All rights reserved
                </Typography>
              </Box>

              <Box className={classes.botomBox2} style={{}}>
                <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  to="policy"
                  onClick={() => {
                    history.push({
                      pathname: "/terms",
                    });
                  }}
                >
                  {" "}
                  Terms & Conditions{" "}
                </ScrollLink>
                <ScrollLink
                  className={classes.menuButton}
                  smooth={true}
                  duration={500}
                  to="policy"
                  onClick={() => {
                    history.push({
                      pathname: "/policy",
                    });
                  }}
                >
                  {" "}
                  Privacy Policy{" "}
                </ScrollLink>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
