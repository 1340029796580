import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiTabs: {
      fixed: {
        width: "100%",
        background: "#000",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "green",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          background: "#787878 ",
        },
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          display: "none",
        },
        "&::before": {
          left: "0",
          right: "0",
          bottom: "0",
          content: '"\\00a0"',
          position: "absolute",
          transition:
            "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderBottom: "1px solid #fff",
          pointerEvents: "none",
        },
      },
    },
    MuiFormControl: {
      root: {
        // width: "100%",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "0px",
      },
    },
    MuiAccordion: {
      root: {
        // "& .Mui-expanded": {
        //   "&:first-child": {
        //     border: "1px solid #FFF",
        //   },
        // },
      },
    },

    MuiAppBar: {
      colorPrimary: {
        color: "#fff",
        backgroundColor: "transparent",
      },
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      root: {
        color: "white",
        border: "1px solid #9F9A9A",
      },
      paddingRight: "0px",
      color: "#fff",
      borderColor: "#fff",
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#fff",
          borderColor: "#fff",
        },
        "&.Mui-focused": {
          color: "#fff",
        },
        // '&.MuiOutlinedInput-adornedEnd':{
        //   paddingRight:"0px"
        // },
      },
    },
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
      root: {
        color: "#52565c",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        // backgroundColor: "transparent",
        backgroundColor: "#000",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      scrollPaper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "@media (max-width:767px)": {
          display: "flex",
        },
      },
      container: {
        height: "100%",
      },
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "500px !important",
      },
    },
    MuiInputBase: {
      root: {
        width: "100%",
      },
      input: {
        fontSize: 14,
        color: "#fff",
        height: "1.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      containedSecondary: {
        background: "transparent !important",
        border: "1px solid #1EB808",
        fontFamily: "'Orbitron', sans-serif",
        // fontFamily: "'Sen', sans-serif",
        color: "#ffff",
        height: "43px",
        padding: "25px 39px",
        filter: "drop-shadow(0px 0px 10px #1EB808)",
        fontSize: "16px !important",
        lineHeight: "21px",
        // marginRight: "10px",
        borderRadius: "100px",
        fontWeight: "600",
        "&:hover": {
          background: "#1EB808 !important",
          color: "#FFFFFF",
        },
      },

      containedPrimary: {
        color: "#000",
        height: "43px",
        padding: "25px 39px !important",
        fontSize: "16px !important",
        boxShadow: "0px 0px 30px #1EB808",
        lineHeight: "21px",
        borderRadius: "100px",
        fontFamily: "'Orbitron', sans-serif",
        // fontFamily: "'Sen', sans-serif",
        background:
          "linear-gradient(180deg, #06FBB6 0%, rgba(255, 255, 255, 0.98) 100%) !important",
        border: "1px solid #1EB808",
        fontWeight: "600",
        "&:hover": {
          background: "transparent !important",
          border: "1px solid #1EB808",
          color: "#FFFFFF",
        },
      },
      contained: {
        color: "white",
        fontWeight: 500,
        padding: "5px 5px",
        backgroundColor: "",
        color: "#fff",
        fontFamily: "'Saira SemiCondensed', sans-serif",
        "&.Mui-disabled": {
          backgroundColor: "#7e7e7e70 !important",
          color: "#fff !important",
        },
      },
      outlinedPrimary: {
        borderRadius: "50px",
        color: "#300760",
        fontWeight: 600,
        padding: "5px 19px",
        border: "2px solid #300760",
        "&:hover": {
          backgroundColor: "#f30065",
          border: "2px solid #f30065",
          color: "#fff",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
      root: {
        "&.Mui-disabled": {
          color: "#fff9 !important",
        },
      },
      text: {
        fontFamily: "'Saira Semi Condensed', sans-serif",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#fff",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
